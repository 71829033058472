.access-log-form-body {
    padding-top: 5px;
    padding-bottom: 60px;
    height: 60%;
    width: 70%;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
    margin-left:15rem;
    row-gap: 0.5rem;
    font-family: "Montserrat", sans-serif;
    font-size:14px;
  }
  .access-logbody-main{
    position: absolute;
    inset: 0;
    top: 13%;
    bottom: 2%;
    color: white;
    left: 8%;
  }
  .scrollable{
    height: 80%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 1.2rem;
    font-family: "Montserrat", sans-serif;
    font-size:14px;
    overflow-y: scroll;
  }
  .access-calender{
    width:200px;
    height:34px ;
    background-color: #1b3643 ;
    border: #1b3643;
  
  }
  .access-search-bar{
    margin-left: 15rem;
    margin-top: 15px;
    height: 10%;
    width: 70%;
    background-color: black;
    border-radius: 15px;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    display: flex;
  }
  
  .submit-btn{
    background-color: #00b7a8;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 25px;
    width: 10%;
    height: 2.5rem;
    border-radius: 15px;
    color: white;
    font-family: "Montserrat", sans-serif;
    background: linear-gradient(180deg, #00b7a8 0%, #044646 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    opacity: 0.8;
  }
  
  .submit-btn:hover {
    opacity: 1;
  }
  .access-logs-heading {
    display: grid;
    grid-template-columns: 1fr 4fr 3.6fr 4fr 1.79fr 3.6fr ;
    width: 100%;
    font-size: small;
    height: 10%;
    align-items: center;
    font-weight: bold;
    font-size: 0.8rem;
    background-color: #15202f;
    margin-top: 1rem;
    margin-bottom: 1rem;
    gap:44px;
    font-family: "Montserrat", sans-serif;    
  }
  .nowrap-text {
    white-space: nowrap;
    text-overflow: ellipsis; 
  }