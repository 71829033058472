@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
.custom-scrollbars__content {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.custom-scrollbars__content::-webkit-scrollbar {
  display: none;
}

ul::-webkit-scrollbar {
  width: 5px;
  opacity: 0.7;
}

ul::-webkit-scrollbar-track {
  background: #454545;
  border-radius: 10px;
  opacity: 0.7;
}

ul::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #1effed;
  opacity: 0.7;
}
ul::-webkit-scrollbar-track:hover {
  opacity: 1;
}

.dropdown-title {
  width: 50%;
}

.input_vin {
  background: inherit;
  color: white;
  width: 100%;
}
*:focus {
  outline: none;
}
input {
  border: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 300;
}
.input_vin:hover {
  cursor: pointer;
}
input::placeholder {
  color: white;
  opacity: 0.8;
}

.ant-select {
  width: 100%;
}

.dropdown-select .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  color: white;
}
.dropdown-select .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text;
  height: 2.5rem;
  border-radius: 13px;
  align-items: center;
  font-size: medium;
  padding-left: 1rem;
}

.dropdown-select .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: auto;
  color: inherit;
  height: 2.5rem;
  display: flex;
  align-items: center;
  padding-left: 0.4rem;
}
.dropdown-select .ant-select-selection-placeholder {
  color: white;
  opacity: 0.8;
}

 .rc-virtual-list {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

.ant-select-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 4px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  position: absolute;
  top: -9999px;
  z-index: 1050;
  overflow: hidden;
  font-variant: initial;
  background-color: #ffffff;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  background: #1B3643;
  color: white;
}

.ant-select-dropdown .ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
}

.ant-select-dropdown .ant-select-item-option-content:hover {
  /* background-color: #1effed; */
  color: #000;
  border-radius: 5px;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #1B525C;
  color: white;
}
.ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
}
.ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #1B525C;
  color: #000 !important;
}
.ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled){
  background-color: #1B525C;
  color: white
}
.ant-select-dropdown .ant-select-item-option-content:hover {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white
}
.ant-select-item-option .ant-select-item-option-selected{
  color: white
}
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.88);
  font-weight: 400;
  color: white
  
}
.ant-select-dropdown .ant-select-item-option {
  display: flex;
  color: white
}
.ant-empty-small .ant-empty-image {
  height: 35px;
  color: white;
}
.ant-empty-normal .ant-empty-description {
  color: white;
}
.rc-virtual-list-scrollbar-thumb {
  background-color: #1effed !important;
}

@media (max-width: 1100px) {
  .dropdown-select .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 2rem;
    font-size: 1rem;
  }

  .time_pick .ant-picker {
    border: none;
    font-family: "Montserrat", sans-serif;
    height: 2rem;
    border-radius: 13px;
    
}
.dropdown-select .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  color: white;
  height: 2rem;
  display: flex;
  align-items: center;
}
.dropdown-select .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: auto;
  color: inherit;
  height: 2rem;
  display: flex;
  align-items: center;
  padding-left: 0.4rem;
}


} 