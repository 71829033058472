
.observationTable th, .observationTable td {
    padding: 0px 15px 0px 0px;
   
  }
  .observationTable th {
    text-align: left;
  }
  .observationColumn {
    word-wrap: break-word;  /* Ensures long words break */
    white-space: normal;    /* Allows wrapping */
    overflow-wrap: break-word; /* Alternative for word wrapping */
    max-width: 25rem;
    width: 100%;
    text-align: left;
    padding-left: 20px;
  }

  .observationTableContainer {
    width: 100%;
    border-radius: 10px;
    overflow: hidden; /* Ensures smooth rounded corners */
  }
  .thObservations{
    padding-left: 20px;
  }
  .fgqc-heading{
    text-align: left;
    white-space: nowrap;
    padding-right: 0px;

  }
  .write-observation-container {
    display: flex;
    gap: 15px;
  }
  
  .vinDropdown {
    width: 50%;
    align-self: flex-start;
  }
  
  .observation-input-container {
    width: 50%;
    height: 40%;
  }
  
  .observation-input {
    width: 70%;
    height: 80%;
    padding: 14px;
    margin-left: 126px;
    font-size: 14px;
    border-radius: 10px;
    border: 1px solid #ccc;
    resize: vertical;
    align-self: flex-end;
    
  }
  
  .write-submit-btn {
   font-family: "Montserrat", sans-serif;
    font-size: 12px;
    background-color: #00b7a8;
    background: linear-gradient(180deg, #00b7a8 0%, #044646 100%);
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .write-submit-btn:hover {
    background-color: #00b7a8;
    background: linear-gradient(180deg, #327a74 0%, #044646 100%);
    
  }



  