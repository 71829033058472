.container{
    height: 80%;
    width:70%;
    margin: 2rem 2rem 1rem 2rem;
}

.tickets-container{
    color: white;
    background: var(--Gradient-2, linear-gradient(180deg, #171717 -12.86%, #3e4148 -12.85%, #1b1c1e 104.45%));
    border-radius: 10px 0 0 10px;
    font-family: "Montserrat", sans-serif;
}

.access-search-bar{
    /* padding-top: 25px; */
    margin-left: 15rem;
    margin-top: 15px;
    height: 10%;
    width: 70%;
    background-color: black;
    border-radius: 15px;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    display: flex;
}
.service-history-heading{
    
        display: grid;
        grid-template-columns: 0.6fr 3.1fr 4.1fr 3.6fr 3.5fr 3.3fr 4.5fr ;
        width: 100%;
        font-size: small;
        height: 10%;
        align-items: center;
        font-weight: bold;
        font-size: 0.8rem;
        background-color: #15202f;
        margin-top: 1rem;
        margin-bottom: 1rem;
        gap:43px;
        font-family: "Montserrat", sans-serif;
        position: sticky;
        top: 0;
        z-index: 2;
        row-gap: 1.2rem;
        

}
.service-table {
   
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size:13px;
    border-spacing: 20px 20px;
    
}

.filter-container{
    width:89%;
    background:#000000cc;
    margin-bottom: 8px;
    padding: 1rem;
    display: flex;
    justify-content: space-evenly;
    gap: 1rem;
    border-radius: 1rem;
    align-items: center;
}
.filter-container .ant-select-single{
    width: 12rem;
}
.table-container{
    overflow-y: scroll;
    max-height: 400px;
    top:1rem;
    width: 92%;
    max-height: 84%;
    min-height: 87%;
    background:#000000cc;
    border-radius: 1rem;
}

.service-table td{
    padding: 10px; 
    height: 1rem;
    width: 10rem;
    word-spacing: 3px;
}
.filter-container label {
    margin-right: 8px; /* space between label and input field */
}