/* Styles for table headers and table data cells */
.EditableTable th, .EditableTable td {
  padding: 0px 15px 0px 0px;
  text-align: left;
}

/* Styles for observation column ensuring text wraps properly */
.observationColumn {
  word-wrap: break-word;  /* Ensures long words break */
  white-space: normal;    /* Allows wrapping */
  overflow-wrap: break-word; /* Alternative for word wrapping */
  max-width: 10rem;
  width: 100%;
  text-align: left;
  padding-left: 20px;
}

/* Container styling for the observations table */
.observationTableContainer {
  width: 100%;
  border-radius: 10px;
  overflow: hidden; /* Ensures smooth rounded corners */
}

/* Styles for the observations table header */
.thObservations {
  padding-left: 20px;
}

/* Styling for FGQC heading text */
.fgqc-heading {
  text-align: left;
  white-space: nowrap;
  padding-right: 0px;
}

/* Container for writing observations with spacing */
.write-observation-container {
  display: flex;
  gap: 15px;
}

/* Styling for VIN dropdown selector */
.vinDropdown {
  width: 50%;
  align-self: flex-start;
}

/* Container for observation input field */
.observation-input-container {
  width: 50%;
  height: 40%;
}

/* Styling for observation input field */
.observation-input {
  width: 70%;
  height: 80%;
  padding: 14px;
  margin-left: 126px;
  font-size: 14px;
  border-radius: 10px;
  border: 1px solid #ccc;
  resize: vertical;
  align-self: flex-end;
}

/* Styling for submit button */
.write-submit-btn {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  background-color: #00b7a8;
  background: linear-gradient(180deg, #00b7a8 0%, #044646 100%);
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  padding: 3px 7px;
}

/* Hover effect for submit button */
.write-submit-btn:hover {
  background-color: #00b7a8;
  background: linear-gradient(180deg, #327a74 0%, #044646 100%);
}

/* Center aligning edit buttons */
.editButtons {
  text-align: center;
  vertical-align: middle;
}

/* Styling for editable input fields */
.editInput {
  background-color: black;
  color: aliceblue;
  height: 30%;
  width: 95%;
  padding: 9px;
  padding-left: 2px;
  font-size: 13px;
  border: 1px solid #000;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
}

/* Focus effect for editable input fields */
.editInput:focus {
  background-color: black;
  color: aliceblue;
  font-size: 13px;
  border-color: transparent;
}
