/* container */
.container {
    position: absolute;
    height: 87%;
    top: 10%;
    left: 18%;
    width: 74%;
    color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
  }

  /* chatBox */
  .chatBox {
    width: 100%;
    height: 102%;
    background-color: rgba(51, 51, 51, 0.2);
    /* border-radius: 15px; */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  /* chatHeader */
  .chatHeader {
    display: flex;
    justify-content: center;
    background-color: #000;
    color: #fff;
    /* border-radius: 10px; */
    align-items: center;
    padding: 4px;

  }
  .agentLogo {
    height: 50px; /* Set the height of the logo */
    margin-right: 15px; /* Space between logo and company name */
  }
  
  h2{
    /* font-size: 2rem; */
    color: white; /* White text for the company name */
    margin: 0;
  }
  /* chatHistory */
  .chatHistory {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  /* General Chat Bubble Style */
.chatBubble {
  max-width: 50%;
  padding: 3px ;
  border-radius: 15px;
  word-wrap: break-word;
  margin-bottom: 5px;
  max-width: 70%;
  padding: 5px 5px;
  /* border-radius: 15px; */
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  
  display: inline-block;
  box-shadow: 2px 2px  rgba(75, 78, 78, 0.923);

}

/* Style for User's Chat Bubble */
.userBubble {
  background-color: #00b7a8;
  background: linear-gradient(180deg, #00b7a8, #044646);
  color: #fff;
  align-self: flex-end;
}

/* Style for System's Chat Bubble */
.systemBubble {
  background-color:#2f302f;
  background: linear-gradient(180deg,  #2f302f 0%, #000 100%);
  color: whitesmoke;
  align-self: flex-start;
}
/* .systemBubble {
  background-color: #2f302f;
  background: linear-gradient(180deg, #2f302f 0%, #000 100%);
  color: whitesmoke;
  align-self: flex-start;
  padding: 15px;
  border-radius: 10px;
  width: 100%; 
  display: flex;
  justify-content: center; 
} */


  
  /* inputArea */
  .inputArea {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ccc;
    gap: 10px;
  }

  .sendButton .send{
    height: 20px; /* Set the height of the logo */
    /* margin-right: 15px; */
    align-items: center;

  }
  
  /* input */
  .input {
    flex: 1;
    color: #fff;
    padding: 10px;
    padding-left: 20px;
    box-shadow: 2px 2px 2px 2px rgba(11, 7, 7, 0.963);
    font-size: 14;
    border-radius: 20px;
    outline: none;
    background-color: #333;
    font-weight: 300;
  }


  
  
  /* sendButton */
  .sendButton {
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: 2px 2px rgba(255, 255, 255, 0.1);

    /* box-shadow: #fff; */
  }
  
  /* suggestions */
  .suggestions {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
  }
  
  /* suggestionButton */
  .suggestionButton {
    padding: 10px 15px;
    background-color: #c1e7ec;
    border: 1px solid #000;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    box-shadow: 2px 2px  rgb(21, 45, 47); 
  }
  
  .suggestionButton:hover {
    background-color: #7b848d; /* Darker blue on hover */
  }


  /* Style for the VIN Dropdown */
.vinDropdown {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.css-166bipr-Input{
  font-family: 'Montserrat', sans-serif;
  font-size: 14;
  color:#ffffff !important;
}
.vinDropdown label {
  color: white; /* Label color */
  margin-bottom: 8px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  
}

.vinDropdown select {
  background-color: #333; /* Matching the dark background */
  color: white; /* White text for contrast */
  font-size: 14px; /* Font size to match other text */
  padding: 10px 20px; /* Matching padding */
  border: 1px solid #ccc; /* Light border for contrast */
  border-radius: 20px; /* Rounded corners for consistency */
  outline: none; /* Remove the default focus outline */
  width: 16%; /* Take up full width */
  box-shadow: 2px 2px rgba(255, 255, 255, 0.1); /* Light box shadow */
}

/* Style for the select dropdown on hover */
.vinDropdown select:hover {
  background-color: #444; /* Slightly lighter dark background */
  border-color: #007bff; /* Blue border when hovering */
}

/* Style for the select dropdown on focus */
.vinDropdown select:focus {
  border-color: #007bff; /* Blue border on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Blue glow effect */
}

/* Style for the option elements */
.vinDropdown select option {
  background-color: #333; /* Matching the background color */
  color: white; /* White text for contrast */
  padding: 10px 20px; /* Consistent padding */
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  
}

/* Style for option hover */
.vinDropdown select option:hover {
  background-color: #444; /* Slightly lighter on hover */
}

/* Style for the dropdown placeholder */
.vinDropdown select option[value=""] {
  color: #ccc; /* Light color for placeholder */
}

.bubbleTableContainer {
  max-width: 50%; /* Limit width for the bubble */
  margin: 10px auto; /* Center the bubble horizontally */
  border-radius: 15px; /* Make the table container rounded */
  
  padding: 10px; /* Padding inside the bubble */
  color: #ffffff; /* White font color */
  font-size: 14px; /* Text size */
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for the bubble */
  font-family: 'Montserrat', sans-serif;
 
  word-wrap: break-word; /* Ensure long text breaks */
}
.css-qbdosj-Input {color: #ffffff !important;}
.bubbleTable {
  width: 30%; /* Use full width within the bubble */
  border-collapse: collapse; /* Remove gaps between borders */
  color: #ffffff; /* White text for the table */
}

.bubbleTable th,
.bubbleTable td {
  text-align: left; /* Align text to the left */
  padding: 8px; /* Add padding inside cells */
  border: none; /* Remove borders for a clean bubble look */
}

.bubbleTable th {
  font-weight: bold; /* Bold headers */
  text-transform: uppercase; /* Uppercase text for headers */
}

.bubbleTable tr:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.2); /* Light transparency for alternate rows */
}

.bubbleTable tr:nth-child(odd) {
  background-color: transparent; /* Transparent for odd rows */
}

.bubbleTable td {
  font-size: 14px; /* Font size for data cells */
}



.chatMessage {
  display: flex;
  margin: 10px 0;
  max-width: 100%;
}

.user {
  justify-content: flex-end; /* Align user messages to the right */
}

.system {
  justify-content: flex-start; /* Align system messages to the left */
}


.greenText {
  color: rgb(43, 229, 43);
  font-weight: bold;
}

.redText {
  color: red;
  font-weight: bold;
}

.onlineStatus {
  color: green;
  font-weight: bold;
}

.offlineStatus {
  color: red;
  font-weight: bold;
}

